import 'babel-polyfill';
import './styles.scss';
import Rails from 'rails-ujs';

import Vue from 'vue/dist/vue.esm';

import BootstrapVue from 'bootstrap-vue'
import VueTheMask from 'vue-the-mask';
import vSelect from 'vue-select'
import VCalendar from 'v-calendar';
// import 'v-calendar/lib/v-calendar.min.css';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
Vue.axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
Vue.axios.defaults.headers.common['Accept'] = 'application/json';
Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';


Rails.start();
window.$ = $;
Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VCalendar, {
  locale: 'ru',
  firstDayOfWeek: 2,
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'YYYY-MM-DD',
    data: ['YYYY-MM-DD']
  }
});

//region Quill editor
Vue.component('v-select', vSelect)

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, /* { default global options } */)
//endregion

import $ from 'jquery';
import { RetinaTag } from '../src/modules/retina_tag';
require("jquery")
require("@nathanvda/cocoon")

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    components: {
      AbonementRaceSelectionForm: () => import('../src/components/abonement-race-selection-form'),
      ArchivedHeat: () => import('../src/components/archived_heat'),
      ButtonTo: () => import('../src/components/button-to'),
      ClinicsPage: () => import('../src/components/clinics_page'),
      CustomAlerts: () => import('../src/components/custom_alerts'),
      EventLocationMap: () => import('../src/components/event-location-map'),
      HeatList: () => import('../src/components/heat-list.vue'),
      ProductCard: () => import('../src/components/product_card'),
      RelayTeamSelector: () => import('../src/components/relay_team_selector'),
      SelectHeatOwner: () => import('../src/components/select_heat_owner'),
      SelectPerson: () => import('../src/components/select-person'),
      SubscriptionForm: () => import('../src/components/subscription-form'),
      StatusIcon: () => import('../src/components/status-icon'),
    },
    data: () => ({
      globalVar: general.globalVar
    })
  });

  new RetinaTag();
});

Vue.directive('init', {
  bind: function(el, binding, vnode) {
    vnode.context[binding.arg] = binding.value;
  }
});
